import { cache, createAsync, useNavigate, useParams } from "@solidjs/router";
import {
  Accessor,
  createEffect,
  createSignal,
  Match,
  onCleanup,
  onMount,
  Show,
  Switch,
} from "solid-js";
import { cardGenerationFailed } from "~/assets/assets";
import { getProcurementOrderDetail } from "~/server/apis/client_apis";
import {
  getProcurementOrderProcessingRouteData,
  ProcurementOrderProcessingRouteData,
} from "~/server/data/procurement_order_processing_route_data";
import { ProcurementOrderStatus } from "~/server/types/order";
import { ButtonRegular, ThreeDotLoader } from "~/widgets/button";
import { Divider } from "~/widgets/divider";
import { Spacer } from "~/widgets/spacer";

const getProcurementOrderDetailRouteData$C = cache(
  getProcurementOrderProcessingRouteData,
  "procurement_order_processing"
);

export default function ProcurementOrderProcessing() {
  const params = useParams();
  const routeData: Accessor<ProcurementOrderProcessingRouteData | undefined> =
    createAsync<ProcurementOrderProcessingRouteData>(() => {
      return getProcurementOrderDetailRouteData$C(params.orderId);
    });
  const [state, setState] = createSignal<ProcurementOrderStatus | undefined>();
  let interval: any;
  let longInterval: any;
  const [showTakingLonger, setShowTakingLonger] = createSignal(false);
  const navigator = useNavigate();
  const [showErrorState, setShowErrorState] = createSignal(false);
  const [orderProgress, setOrderProgress] = createSignal(0);

  onMount(async () => {
    await processOrder();
  });

  const processOrder = async () => {
    try {
      const result = await getProcurementOrderDetail({
        orderId: params.orderId,
      });
      if (result) {
        setState(result.status);
        setOrderProgress(result.progressPercentage);
        if (result.status === "FULFILLED") {
          navigator("/purchases/" + params.orderId, { replace: true });
        } else if (result.status === "FAILED") {
          clearInterval(interval);
          clearInterval(longInterval);
        }
      }
    } catch (error) {
      console.error("Failed to process order:", error);
    }
  };

  createEffect(() => {
    interval = setInterval(() => {
      processOrder();
    }, 10000);
  });

  createEffect(() => {
    longInterval = setInterval(() => {
      setShowTakingLonger(true);
    }, 120000);
  });

  onCleanup(() => {
    clearInterval(interval);
    clearInterval(longInterval);
  });

  return (
    <Switch>
      <Match when={state() == "FAILED"}>
        <ProcurementOrderFailure />
      </Match>
      <Match when={true}>
        <div class="flex w-full flex-col items-center justify-center text-h5">
          <Show
            when={orderProgress() > 0}
            fallback={<ThreeDotLoader color="#999" />}
          >
            <div class="mt-6 flex w-[300px]">
              <div
                class={`h-1  bg-black ${orderProgress() === 100 ? "rounded" : "rounded-s"}`}
                style={{
                  width: `${orderProgress()}%`,
                }}
              ></div>
              <div
                class="h-1  rounded-e bg-gray-200"
                style={{
                  width: `${100 - orderProgress()}%`,
                }}
              ></div>
            </div>
          </Show>
          <Spacer height={20} />
          <div
            class={`${showTakingLonger() ? "text-h5 text-textNormal" : "text-h3 text-textDark"}`}
          >
            You order is getting ready
          </div>
          <Show when={showTakingLonger()}>
            <div class="flex flex-col items-center justify-center">
              <div class="my-3 h-1 w-14 rounded-[4px] bg-baseTertiaryMedium text-center"></div>
              <div class=" text-center text-h3 text-textDark">
                <div>It’s will take sometime to generate</div>
                {/* <div>we’ll inform you once its ready</div> */}
              </div>
              <div
                class="mt-4 flex h-10 w-40 cursor-pointer items-center justify-center rounded-[8px] border border-basePrimaryDark py-3 text-center text-mediumBold"
                onClick={() => {
                  navigator("/catalogue", { replace: true });
                }}
              >
                Go to home
              </div>
            </div>
          </Show>
        </div>
      </Match>
    </Switch>
  );

  function ProcurementOrderFailure() {
    return (
      <div class="flex w-full flex-col content-center items-center justify-center">
        <img
          src={cardGenerationFailed}
          class="h-[120px] w-[150px]"
          alt="Card generation failed"
        />
        <div class="pb-1 pt-3 text-h5 text-errorDark">
          Card failed to generate
        </div>
        <div class="my-3 h-1 w-14 rounded-[4px] bg-baseTertiaryMedium"></div>
        <div class="flex max-w-[30%] text-center text-h3 text-textDark">
          Any money deducted will be refunded in you wallet
        </div>
      </div>
    );
  }
}
